/**
 * StudyPathAssessmentTakerNavMenu
 * Eventually I think this will be used for all Assessment Taker l8y nav but for now I'm filing it with the study path AT
 * It might also make sense to just store this with the Learnosity Container since it is so tightly coupled to that component.
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FaMinus, FaPlus } from 'react-icons/fa';

import removeQuestionDuplicates from 'utils/removeQuestionDuplicates';
import StudyPathAssessmentTakerNavItem from './StudyPathAssessmentTakerNavItem';
import { ClarityEnum, YesNo } from 'types/backend/shared.types';
import { AssessTypeEnum } from 'types/backend/assessments.types';
import { StudyPathEnrichedQuestion } from '../../sharedStudyPathFunctions';
import { ClarityHash } from 'types/common.types';
import './StudyPathAssessmentTakerNavMenu.scss';

function NavHeader({ className, disabled = false, expanded, label, toggleExpanded }: {
  className: string
  disabled?: boolean
  label: string
  expanded: boolean
  toggleExpanded: (tog: boolean) => void
}) {
  return (
    <div className={`study-path-assessment-taker__question-nav-header ${className}`}>
      <div>{label}</div>
      <button
        aria-label={`${expanded ? 'Collapse' : 'Expand'} Question List`}
        disabled={disabled}
        onClick={() => toggleExpanded(!expanded)}
      >
        { expanded ? <FaMinus /> : <FaPlus /> }
      </button>
    </div>
  );
}

function StudyPathAssessmentTakerNavMenu({
  clarityHash,
  handleQuestionNav,
  activeQuestion,
  todoQuestions,
  pastQuestions,
}: {
  clarityHash: ClarityHash
  handleQuestionNav: (l8yId: string, loNumber: string) => void
  activeQuestion: string
  pastQuestions: Array<StudyPathEnrichedQuestion>
  todoQuestions: Array<StudyPathEnrichedQuestion>
}) {
  const [showTodo, toggleShowTodo] = useState(true);
  const [showPastQuestions, toggleShowPastQuestions] = useState(false);
  // See if past menu should be expanded
  const pastL8yArray = pastQuestions.map((q) => q.question.l8yId);
  const questionFromThePast = pastL8yArray.includes(activeQuestion);

  const toDoQuestionsNoDuplicates = removeQuestionDuplicates(todoQuestions);
  const pastQuestionsNoDuplicates = removeQuestionDuplicates(pastQuestions);

  return (
    <div className="spat-nav-menu col-xs-12 col-sm-3">
      <nav aria-label="Assessment table of contents" className="study-path-assessment-taker__question-nav">
        <NavHeader
          className="todo-questions__header"
          label={`To Do (${toDoQuestionsNoDuplicates.length})`}
          expanded={showTodo}
          toggleExpanded={toggleShowTodo}
        />
        {showTodo && (
          <ul className="spat-nav-list todo-questions">
            {toDoQuestionsNoDuplicates.map((questionData) => (
              <StudyPathAssessmentTakerNavItem
                clarityHash={clarityHash}
                handleQuestionNav={handleQuestionNav}
                isActive={questionData.question.l8yId === activeQuestion}
                key={`todo_${questionData.id}`}
                questionData={questionData}
              />
            ))}
          </ul>
        )}
        <NavHeader
          className="past-questions__header"
          disabled={questionFromThePast}
          label={`Past Questions (${pastQuestionsNoDuplicates.length})`}
          expanded={showPastQuestions}
          toggleExpanded={toggleShowPastQuestions}
        />
        {(showPastQuestions || questionFromThePast) && (
          <ul className="spat-nav-list past-questions">
            {pastQuestionsNoDuplicates.map((questionData) => (
              <StudyPathAssessmentTakerNavItem
                clarityHash={clarityHash}
                handleQuestionNav={handleQuestionNav}
                isActive={questionData.question.l8yId === activeQuestion}
                key={`past_${questionData.id}`}
                questionData={questionData}
              />
            ))}
          </ul>
        )}
      </nav>
    </div>
  );
}

export const QuestionDataProp = PropTypes.shape({
  assessNumber: PropTypes.number,
  assessment: PropTypes.shape({
    assessType: PropTypes.oneOf(Object.values(AssessTypeEnum)).isRequired,
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
  assessType: PropTypes.oneOf(Object.values(AssessTypeEnum)),
  id: PropTypes.number.isRequired,
  loNumber: PropTypes.string.isRequired,
  question: PropTypes.shape({
    l8yId: PropTypes.string.isRequired,
    loNumber: PropTypes.string,
  }).isRequired,
  questionNumber: PropTypes.number.isRequired,
  studentAssessmentQuestion: PropTypes.shape({
    pointsAvailableToRecap: PropTypes.number,
    latestStudentAssessmentQuestionAttempt: PropTypes.shape({
      clarity: PropTypes.oneOf(Object.values(ClarityEnum)),
      isCorrect: PropTypes.oneOf(Object.values(YesNo)).isRequired,
    }),
  }).isRequired,
});

StudyPathAssessmentTakerNavMenu.propTypes = {
  clarityHash: PropTypes.objectOf(PropTypes.string).isRequired,
  handleQuestionNav: PropTypes.func.isRequired,
  activeQuestion: PropTypes.string.isRequired,
  todoQuestions: PropTypes.arrayOf(QuestionDataProp.isRequired).isRequired,
  pastQuestions: PropTypes.arrayOf(QuestionDataProp.isRequired).isRequired,
};

export default StudyPathAssessmentTakerNavMenu;
