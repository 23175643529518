import { GenericObject, YesNo } from 'types/backend/shared.types';

export enum Steps {
  Intro = 'Intro',
  Assessment = 'Assessment',
  Outro = 'Outro',
}

export const PageStates: GenericObject = {
  [Steps.Intro]: {
    next: Steps.Assessment,
  },
  [Steps.Assessment]: {
    prev: Steps.Assessment,
    next: Steps.Outro,
  },
  [Steps.Outro]: {
    prev: Steps.Assessment,
  },
};

export interface ScoreDatum {
  isCorrect: YesNo
  score: number
}

export interface ScoreData {
  [key: string]: ScoreDatum
}

export enum AssessmentTakerQuestionStage {
  INIT = 'INIT',
  ANSWER_CHANGED = 'ANSWER_CHANGED',
  CLARITY_SELECTED = 'CLARITY_SELECTED',
  VALIDATED_CORRECT = 'VALIDATED_CORRECT',
  VALIDATED_INCORRECT = 'VALIDATED_INCORRECT',
}
