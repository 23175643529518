import React, {
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { FeatureFlags } from 'App';
import apiNext from 'api-next';
import useEffectOnce from 'hooks/useEffectOnce';
import { formatPlural, mailToHref } from 'utils/commonFormattingFunctions';
import sharedStrings from 'sharedStrings';
import { useAppSelector } from 'store';
import retrieveActiveCombinedQuestions, { ActiveCombinedQuestion } from 'store/selectors/retrieveActiveCombinedQuestions';
import LearnosityContainer, { L8yEventPayload } from 'shared-components/LearnosityContainer/LearnosityContainer';
import LoadingSpinner from 'shared-components/Spinner/LoadingSpinner';
import KebabMenu from 'shared-components/KebabMenu/KebabMenu';
import BetterTooltip from 'shared-components/Tooltip/BetterTooltip';
import QuestionActionDropdown from './QuestionActionDropdown';
import CustomItemIcon from 'shared-components/CustomItemIcon/CustomItemIcon';
import { useConfirmationPrompt } from 'shared-components/ConfirmationPrompt/ConfirmationPromptContext';
import { QuestionPreviewContext } from 'instructor/controllers/Course/AssessmentBuilderController/AssessmentBuilderController';
import { L8yContainerEvents, L8yErrorData } from 'shared-components/LearnosityContainer/LearnosityContainer.types';
import { AssessmentLocation, LibraryTypeEnum } from 'types/backend/shared.types';
import { AssessmentQuestionMetadata, BasicQuestionForPreview } from 'utils/getAssessmentQuestionsMetadata';
import { QuestionActionEnum, QuestionActionPayload } from 'instructor/controllers/Course/AssessmentBuilderController/AssessmentBuilderController.types';
import { L8ySessionState } from 'types/backend/l8ySessions.types';
import { L8yBasicItem } from 'types/backend/questions.types';
import { AssessmentModeEnum, RenderingTypeEnum } from 'types/backend/assessmentInit.types';
import { ContentTypeEnum, PositionEnum } from 'types/common.types';
import './QuestionPreview.scss';

function QuestionPreview({
  questions,
  userId,
  triggerQuestionAction,
  initialQuestionId,
  launchAssessmentId,
  isAssessmentBuilder,
  onCopyQuestion,
}: {
  questions: Array<BasicQuestionForPreview> | Array<AssessmentQuestionMetadata>
  userId: string
  triggerQuestionAction?: (action: QuestionActionEnum, payload: Pick<QuestionActionPayload, 'questionId' >) => void
  initialQuestionId?: number
  launchAssessmentId?: string
  isAssessmentBuilder?: boolean
  onCopyQuestion?: (questionId: number, shouldNavigate: boolean) => void
}) {
  const { triggerConfirmationPrompt } = useConfirmationPrompt();
  const flags = useContext(FeatureFlags);
  const { isProcessingPreview } = useContext(QuestionPreviewContext);
  const activeCombinedQuestions = useAppSelector(retrieveActiveCombinedQuestions);
  const learningObjectives = useAppSelector((store) => store.active.learningObjectives);
  const initialQuestion = questions.find(q => q.questionId === initialQuestionId) || questions[0];
  // accepts BasicQuestionForPreview from StudentScoresController or the more complete AssessmentQuestionMetadata for AssessmentBuilder
  const [activeQuestion, setActiveQuestion] = useState<BasicQuestionForPreview | AssessmentQuestionMetadata>(initialQuestion);
  const [activeL8yId, setActiveL8yId] = useState(initialQuestion.l8yId);
  const { questionId: qId, l8yId, blooms, title, type: questionType, userId: questionUserId } = activeQuestion as AssessmentQuestionMetadata;

  const initL8yItems = questions.map(({ l8yId: lId, type, gradingType }) => ({ l8yId: lId, type, gradingType }));
  const [validL8yItems, setValidL8yItems] = useState<Array<L8yBasicItem>>([]);

  const displayLos = useMemo(() => {
    const { courseLearningObjectives } = activeQuestion;
    if (!!courseLearningObjectives.length) {
      return courseLearningObjectives.map((clo) => ({ key: clo.id, topicName: clo.topic.name, loTitle: clo.title, loNumber: clo._derived.loNumber }));
    } else {
      const acq = activeCombinedQuestions.find(q => q.id === activeQuestion.questionId) as ActiveCombinedQuestion;
      const qLos = learningObjectives.filter(lo => acq.learningObjectiveIds.includes(lo.id));
      return qLos.map(lo => ({ key: lo.id, topicName: '', loTitle: lo.title, loNumber: lo.stringId }));
    }
  }, [activeCombinedQuestions, activeQuestion, learningObjectives]);

  useEffectOnce(() => {
    const getL8yItems = async (itemReferenceIds: Array<string>) => {
      const l8yItems = await apiNext.getL8yItemsByItemReferences(itemReferenceIds);
      const l8yItemRefs = l8yItems.map(({ reference }) => reference);
      if (initL8yItems.length !== l8yItems.length) {
        const invalidL8yIds: Array<string> = [];
        const filteredValidL8yItems = initL8yItems.reduce((acc: Array<L8yBasicItem>, initL8yItem) => {
          if (l8yItemRefs.includes(initL8yItem.l8yId)) {
            acc.push(initL8yItem);
          } else {
            invalidL8yIds.push(initL8yItem.l8yId);
          }
          return acc;
        }, []);
        console.error(`Preview l8y items mismatch with available items: ${JSON.stringify({ invalidL8yIds })}`);
        setValidL8yItems(filteredValidL8yItems);
        triggerConfirmationPrompt({
          title: 'Question Loading Error',
          message: `Please note that ${invalidL8yIds.length} of ${initL8yItems.length} items in this preview are not currently available. This is usually because it can take a few minutes to fully save a newly created question. Thanks for your patience!`,
          onConfirm: () => {},
        });
      } else {
        setValidL8yItems(initL8yItems);
      }
    };
    getL8yItems(initL8yItems.map(item => item.l8yId));
  });

  useEffect(() => {
    const newActiveQuestion = questions.find(q => q.questionId === activeQuestion.questionId);
    !!newActiveQuestion && setActiveQuestion({ ...newActiveQuestion });
  }, [questions, activeQuestion.questionId]);

  function handleL8yEvents({ type, data }: L8yEventPayload) {
    if (type === L8yContainerEvents.ITEM_CHANGED) {
      const { activeL8yRef } = data;
      const newActiveQuestion = questions.find(q => q.l8yId === activeL8yRef);
      if (newActiveQuestion && activeL8yId !== activeL8yRef) {
        setActiveQuestion(newActiveQuestion);
        setActiveL8yId(activeL8yRef);
      }
    }
    return;
  }

  const handleL8yErrors = (error: L8yErrorData) => {
    console.error('Error from L8y author container', error);
    switch (error.code) {
      case 20013: {
        const { msg } = error;
        const splitMsg = msg.split('Items references: ');
        const badItemRefs = splitMsg[1].split(', ');
        console.warn('badItemRefs', badItemRefs);
        break;
      }
    }
  };

  const questionIssueLink = mailToHref(sharedStrings.CODON_SUPPORT_EMAIL, `Report an issue with item ${l8yId}`, `What kind of issue are you encountering with item ${l8yId}?`);
  const showAssessmentBuilderOptions = isAssessmentBuilder === true;
  const isCustomQuestion = questionType === LibraryTypeEnum.User;
  const isUserQuestion = isCustomQuestion && questionUserId === userId;
  const isOtherUserQuestion = isCustomQuestion && questionUserId !== userId;

  const kebabMenuItems = [
    {
      label: 'Change linked LOs',
      onClick: () => !!triggerQuestionAction && triggerQuestionAction(QuestionActionEnum.EditQuestionLearningObjectives, { questionId: qId }),
      show: showAssessmentBuilderOptions && flags.enableCustomLos && !isOtherUserQuestion,
    },
    {
      label: `Make a ${isUserQuestion ? 'new ' : ''}copy and modify`,
      onClick: () => !!onCopyQuestion && onCopyQuestion(qId, true),
      show: showAssessmentBuilderOptions && !isOtherUserQuestion,
    },
    {
      label: 'Edit item',
      onClick: () => !!triggerQuestionAction && triggerQuestionAction(QuestionActionEnum.EditQuestion, { questionId: qId }),
      show: showAssessmentBuilderOptions && isUserQuestion,
    },
    {
      label: 'Report an issue with this item',
      url: questionIssueLink,
      external: true,
    },
  ];

  if (!validL8yItems.length) {
    return <LoadingSpinner />;
  }
  return (
    <div className="question-preview">
      <div className="question-preview__header row">
        <div className="question-preview__info col-xs-5">
          <div className="question-preview__info-title">
            <strong>Title:</strong> {title}
          </div>
          <div className="question-preview__info-details">
            {!!displayLos.length ? (
              <div className="question-preview__info-item">
                <div className="question-preview__info-item-lo-header">{formatPlural('LO', displayLos.length)}:</div>
                <div className="question-preview__lo-number-list">
                  {
                    displayLos.map(dlo => (
                      <div key={dlo.key} className="question-preview__lo-number">
                        <BetterTooltip position={PositionEnum.BottomRight} content={<><b>{dlo.topicName}</b> <p>{dlo.loTitle}</p></>} inModal>
                          <span>{dlo.loNumber}</span>
                        </BetterTooltip>
                      </div>
                    ))
                  }
                </div>
              </div>
            ) : (
              <div className="question-preview__info-item">
                <strong>LO:</strong>&nbsp;None
              </div>
            )}
            <div className="question-preview__info-item">
              <strong>Bloom's:</strong>&nbsp;{blooms}
            </div>
          </div>
        </div>
        <div className="question-preview__actions col-md-5">
          {!!triggerQuestionAction && !!launchAssessmentId && (
            <QuestionActionDropdown
              activeQuestion={activeQuestion}
              launchAssessmentId={launchAssessmentId}
            />
          )}
        </div>
        <div className="question-preview__actions-kebab-box col-md-1">
          {isCustomQuestion && (
            <CustomItemIcon
              ownerUserId={activeQuestion.userId}
              contentType={ContentTypeEnum.Question}
              tipPosition={PositionEnum.Bottom}
              className='question-preview__custom-icon'
              inModal
            />
          )}
          <KebabMenu
            className="question-preview__kebab-menu"
            items={kebabMenuItems}
            disabled={isProcessingPreview}
          />
        </div>
      </div>
      <div className="question-preview__container">
        {isProcessingPreview ? (
          <LoadingSpinner
            loadingMessage="Copying item..."
            shouldTimeout={false}
          />
        ) : (
          <>
            <LearnosityContainer
              assessmentType={null}
              activityId="instructorpreview"
              assessmentMode={AssessmentModeEnum.SubmitPractice}
              enableClarity={false}
              renderingType={RenderingTypeEnum.Assess}
              handleEvents={handleL8yEvents}
              onL8yError={handleL8yErrors}
              items={validL8yItems}
              name="previewQuestionL8yName"
              userId={userId}
              questionData={questions}
              targetL8yId={activeL8yId}
              initState={L8ySessionState.review}
              inReviewMode={false}
              isInstructor
              location={AssessmentLocation.Preview}
              initClarityHash={{}}
              initAttemptsHash={{}}
            />
            <div className="active-preview-l8yid">{activeL8yId}</div>
          </>
        )}
      </div>
    </div>
  );
}
/***
 * Note: L8ySessionState.Preview seems like it would make more sense for initState but AFAIK
 * the Review state is the only one that shows you the answer and maybe has the possibility of
 * showing the distractor rationale immediately.
 * https://reference.learnosity.com/questions-api/initialization#showCorrectAnswers
 * */

QuestionPreview.propTypes = {
  userId: PropTypes.string,
  questions: PropTypes.array.isRequired,
  triggerQuestionAction: PropTypes.func,
  initialQuestionId: PropTypes.number,
  launchAssessmentId: PropTypes.string,
  isAssessmentBuilder: PropTypes.bool,
  onCopyQuestion: PropTypes.func,
};

export default QuestionPreview;
