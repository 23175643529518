import React, { useState } from 'react';

import apiNext from 'api-next';
import { handleStudentAttemptResponse, validateInstructorVatAttempt } from 'utils/assessmentFunctions';
import { isInThePast } from 'utils/dateFormattingFunctions';
import { useConfirmationPrompt } from 'shared-components/ConfirmationPrompt/ConfirmationPromptContext';
import { useAppSelector } from 'store';

import LearnosityContainer, { L8yContainerValidated } from 'shared-components/LearnosityContainer/LearnosityContainer';
import AssessmentTakerNavItem from './AssessmentTakerNavItem';

import { AssessmentContainerProps, AssessmentContainerPropTypes } from '../shared/AssessmentContainerProps';
import { AssessmentControllerQuestion } from 'utils/getAssessmentControllerQuestions';
import { AssessmentLocation, GenericObject, YesNo } from 'types/backend/shared.types';
import { AssessmentModeEnum, RenderingTypeEnum } from 'types/backend/assessmentInit.types';
import { AssessmentTakerQuestionStage } from 'student/controllers/Course/AssessmentTakerController/AssessmentTakerController.types';
import { AssessTypeEnum } from 'types/backend/assessments.types';
import { ClarityHash } from 'types/common.types';
import { L8yContainerEvents, QuestionStatusHash } from 'shared-components/LearnosityContainer/LearnosityContainer.types';
import { ScoreData } from '../AssessmentTakerController.types';
import { StudentAssessmentQuestionApiWithSaqas } from 'types/backend/studentAssessmentQuestions.types';
import { VatHashes } from 'utils/getVatHashesFromSaqa';

function AssessmentTakerContainer({
  assessmentData,
  assessmentType,
  attemptPolicy,
  attemptsHash: initAttemptsHash,
  clarityHash: initClarityHash,
  correctHash: initCorrectHash,
  everCorrectHash: initEverCorrectHash,
  latePointsDeductedHash: initLatePointsDeductedHash,
  recapHash: initRecapHash,
  vatFrozenHash: initVatFrozenHash,
  enableClarity,
  handleExit,
  inReviewMode,
  l8ySessionId,
  pointsHash: initPointsHash,
  questions,
  studentAssessmentId,
  userId,
}: AssessmentContainerProps) {
  const isInstructor = useAppSelector((store) => !!store.state.instructorStudentViewCourseId);
  const [scoreHash, updateScoreHash] = useState<ScoreData | {}>({});
  const [currentStage, setCurrentStage] = useState(AssessmentTakerQuestionStage.INIT);
  const [{ l8yId: initL8yId }] = questions;
  const [activeL8yId, setActiveL8yId] = useState(initL8yId);
  const { attemptsInAt, mergedDueDate, lateDate, name: l8yName } = assessmentData;
  const isAfterLate = isInThePast(!!lateDate ? lateDate : mergedDueDate);

  const { triggerConfirmationPrompt } = useConfirmationPrompt();

  const handleL8yEvents = async ({ type, data }: { type: string; data: GenericObject }) => {
    console.debug(`LearnosityContainer event:: ${type}`, data);
    switch (type) {
      case L8yContainerEvents.ITEM_CHANGED: {
        setCurrentStage(AssessmentTakerQuestionStage.INIT);
        break;
      }
      case L8yContainerEvents.QUESTION_CHANGED: {
        setCurrentStage(AssessmentTakerQuestionStage.ANSWER_CHANGED);
        break;
      }
      case L8yContainerEvents.HANDLE_FINISHED: {
        const exitHashes = data as VatHashes;
        let correctQuestionsArray: Array<StudentAssessmentQuestionApiWithSaqas> = [];
        if (!isInstructor) {
          const studentAssessmentQuestions = await apiNext.getStudentAssessmentQuestions([studentAssessmentId]) as Array<StudentAssessmentQuestionApiWithSaqas>;
          correctQuestionsArray = studentAssessmentQuestions.filter(s => s.gradedStudentAssessmentQuestionAttempt?.isCorrect === YesNo.Yes);
        }
        handleExit({
          correctQuestionsArray,
          ...exitHashes,
        });
        break;
      }
      case L8yContainerEvents.QUESTIONS_LOADED: {
        const { questionIds } = data;
        console.debug(L8yContainerEvents.QUESTIONS_LOADED, questionIds);
        break;
      }
    }
  };

  const handleValidated = async (data: L8yContainerValidated) => {
    const { activeL8yRef, assessmentQuestionId, attemptData, score, isCorrect, clarity, rawMaxScore } = data;
    updateScoreHash({
      ...scoreHash,
      [activeL8yRef]: {
        score,
        isCorrect,
        clarity,
      },
    });
    if (isInstructor) {
      return validateInstructorVatAttempt(assessmentData, data, studentAssessmentId);
    }
    const studentAttemptResponse = await apiNext.createStudentAttempt({
      assessmentQuestionId,
      attemptData,
      clarity,
      isCorrect,
      location: AssessmentLocation.AT,
      rawMaxScore,
      rawPointsEarned: score,
      studentAssessmentId,
    });
    return handleStudentAttemptResponse({
      location: AssessmentLocation.AT,
      assessmentQuestionId,
      studentAttemptResponse,
      studentAssessmentId,
      triggerConfirmationPrompt,
    });
  };
  const handleNavClick = (question: AssessmentControllerQuestion, handleItemNav: (l8yId: string) => void) => {
    setActiveL8yId(question.l8yId);
    handleItemNav(question.l8yId);
  };
  const handleRenderItemNav = (ch: ClarityHash, activeL8yRef: string, handleItemNav: (l8yId: string) => void, questionStatusHash: QuestionStatusHash) => {
    return (
      <div className="col-xs-12 col-sm-3 nav-menu__wrap">
        <nav className="assessment-taker-nav-menu" aria-label="Assessment Table of Contents">
          <div className="nav-item__header"></div>
          <ul className="nav-item-list">
            {questions.map((question, idx) => (
              <AssessmentTakerNavItem
                handleClick={() => handleNavClick(question, handleItemNav)}
                isActive={activeL8yRef === question.l8yId}
                itemIndex={idx}
                l8yId={question.l8yId}
                key={question.id}
                questionStatus={questionStatusHash[question.l8yId]}
                showCorrectness={assessmentType !== AssessTypeEnum.PracticeTest || inReviewMode}
              />
            ))}
          </ul>
          <div className="nav-item__footer"></div>
        </nav>
      </div>
    );
  };

  const items = questions.map(({ gradingType, l8yId, type }) => ({ gradingType, l8yId, type }));

  return (
    <div className="assessment-wrap" data-assessmentstage={currentStage}>
      <LearnosityContainer
        activityId={studentAssessmentId.toString()}
        assessmentMode={AssessmentModeEnum.SubmitPractice}
        assessmentType={assessmentType}
        attemptLimit={attemptsInAt}
        attemptPolicy={attemptPolicy}
        enableClarity={enableClarity}
        handleEvents={handleL8yEvents}
        handleValidated={handleValidated}
        initAttemptsHash={initAttemptsHash}
        initClarityHash={initClarityHash}
        initCorrectHash={initCorrectHash}
        initEverCorrectHash={initEverCorrectHash}
        initLatePointsDeductedHash={initLatePointsDeductedHash}
        initPointsHash={initPointsHash}
        initRecapHash={initRecapHash}
        initVatFrozenHash={initVatFrozenHash}
        inReviewMode={inReviewMode}
        isInstructor={isInstructor}
        isAfterLate={isAfterLate}
        items={items}
        l8yBoxClassName="col-xs-12 col-sm-9"
        l8ySessionId={l8ySessionId}
        location={AssessmentLocation.AT}
        name={l8yName}
        questionData={questions}
        renderingType={RenderingTypeEnum.Assess}
        renderItemNav={handleRenderItemNav}
        targetL8yId={activeL8yId}
        userId={userId}
      />
    </div>
  );
}

AssessmentTakerContainer.props = AssessmentContainerPropTypes;

export default AssessmentTakerContainer;
