/**
 * StudyPathPracticeTestAssessmentTakerNavMenu
 * This is pretty similar to the SPATNavMenu but I think it's different enough to warrant being a different component
 * The SPPTAT nav menu doesn't collapse, all questions are visible
 */

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import removeQuestionDuplicates from 'utils/removeQuestionDuplicates';
import StudyPathAssessmentTakerNavItem from './StudyPathAssessmentTakerNavItem';
import { StudyPathEnrichedQuestion } from '../../sharedStudyPathFunctions';
import { ClarityHash } from 'types/common.types';
import { QuestionDataProp } from './StudyPathAssessmentTakerNavMenu';
import './StudyPathAssessmentTakerNavMenu.scss';

function StudyPathPracticeTestAssessmentTakerNavMenu({
  activeQuestion,
  clarityHash,
  correctQuestions,
  handleQuestionNav,
  incorrectQuestions,
}: {
  activeQuestion: string
  clarityHash: ClarityHash
  correctQuestions: Array<StudyPathEnrichedQuestion>
  handleQuestionNav: (l8yId: string, loNumber: string) => void
  incorrectQuestions: Array<StudyPathEnrichedQuestion>
}) {

  const incorrectQuestionsNoDuplicates = removeQuestionDuplicates(incorrectQuestions);
  const correctQuestionsNoDuplicates = removeQuestionDuplicates(correctQuestions);

  return (
    <div className="spat-nav-menu col-xs-12 col-sm-3">
      <nav aria-label="Assessment table of contents" className="study-path-practice-test-assessment-taker__question-nav">
        <div className="study-path-assessment-taker__question-nav-header todo">
          <div>Missed ({incorrectQuestionsNoDuplicates.length})</div>
        </div>
        <ul className="todo-questions incorrect-questions">
          {incorrectQuestionsNoDuplicates.map((questionData) => (
            <Fragment key={questionData.id}>
              <StudyPathAssessmentTakerNavItem
                handleQuestionNav={handleQuestionNav}
                isActive={questionData.question.l8yId === activeQuestion}
                clarityHash={clarityHash}
                questionData={questionData}
              />
            </Fragment>
          ))}
        </ul>
        <div className="study-path-assessment-taker__question-nav-header past-questions">
          <div>Correct / Complete ({correctQuestionsNoDuplicates.length})</div>
        </div>
        <ul className="past-questions correct-questions">
          {correctQuestionsNoDuplicates.map((questionData) => (
            <Fragment key={questionData.id}>
              <StudyPathAssessmentTakerNavItem
                handleQuestionNav={handleQuestionNav}
                isActive={questionData.question.l8yId === activeQuestion}
                clarityHash={clarityHash}
                questionData={questionData}
              />
            </Fragment>
          ))}
        </ul>
      </nav>
    </div>
  );
}

StudyPathPracticeTestAssessmentTakerNavMenu.propTypes = {
  activeQuestion: PropTypes.string.isRequired,
  clarityHash: PropTypes.objectOf(PropTypes.string).isRequired,
  correctQuestions: PropTypes.arrayOf(QuestionDataProp.isRequired).isRequired,
  handleQuestionNav: PropTypes.func.isRequired,
  incorrectQuestions: PropTypes.arrayOf(QuestionDataProp.isRequired).isRequired,
};

export default StudyPathPracticeTestAssessmentTakerNavMenu;
