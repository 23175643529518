import apiNext, { ErrorableResponse, SimplifiedErrorResponse } from 'api-next';
import { DateTime } from 'luxon';
import getUniqueLosSortedByLoNumber from 'utils/getUniqueLosSortedByLoNumber';
import { assessmentTakerWarningStrings } from 'sharedStrings';
import { calculatePointsEarnedCompletion, calculatePointsEarnedCorrectness } from 'utils/backend/pointCalculationFunctions';
import { saveLogMessage } from './saveLogMessage';
import { FormValues, TabEnum } from '../instructor/controllers/Course/AssessmentBuilderController/AssessmentBuilderController.types';
import { EnrichedCourseLearningObjective } from 'store/selectors/retrieveActiveCourseLearningObjectives';
import {
  MultipleAttemptPolicyEnum,
  MappedAssessmentQuestion,
  PointsHash,
  CorrectHash,
  CreateSummativeAssessmentBody,
  CreateAssessmentBody,
  AssessmentStatus,
  ConfirmationTypeEnum,
} from 'types/common.types';
import { CourseAssessmentPresetApi } from 'types/backend/courseAssessmentPresets.types';
import {
  AssessmentApiBase,
  AssessTypeEnum,
  GradingPolicyEnum,
  SummativeAssessmentApi,
} from 'types/backend/assessments.types';
import { CourseApi } from 'types/backend/courses.types';
import { AssessmentLocation, YesNo } from 'types/backend/shared.types';
import { AssessmentWithEnrollment } from 'store/selectors/retrieveAssessmentsWithEnrollment';
import { StudentAssessmentQuestionAttemptApi, StudentAssessmentQuestionAttemptApiCreateOut } from 'types/backend/studentAssessmentQuestionAttempts.types';
import { FirstAttemptedEnum } from 'types/backend/studentAssessmentQuestions.types';
import { CodonErrorCode } from 'types/backend/error.types';
import { ConfirmationPromptProps } from 'shared-components/ConfirmationPrompt/ConfirmationPromptContext';
import { L8yContainerValidated } from 'shared-components/LearnosityContainer/LearnosityContainer';

/****
 * this does a translation of dropdown selected 'multipleAttemptPolicy' to the API values that represent it in the API
 * these are freeAttempts and pointPenalty
 * technically gradingPolicy could be considered redundant, but including it here keeps all the attempt and point logic together
 * and probably sets us up for future expansion
 */
export const determineFreeAttemptsAndPointPenalty = (multipleAttemptPolicy: MultipleAttemptPolicyEnum | null) => {
  let freeAttempts;
  let pointPenalty;
  switch (multipleAttemptPolicy) {
    case MultipleAttemptPolicyEnum.CorrectnessTypeOne:
      pointPenalty = 0;
      freeAttempts = null;
      break;
    case MultipleAttemptPolicyEnum.CorrectnessTypeTwo:
      pointPenalty = 0.1;
      freeAttempts = 2;
      break;
    case MultipleAttemptPolicyEnum.CorrectnessTypeThree:
      pointPenalty = 0.1;
      freeAttempts = 1;
      break;
    case MultipleAttemptPolicyEnum.CorrectnessTypeFour:
      pointPenalty = 1;
      freeAttempts = 1;
      break;
    default:
      // type 5 (completion), type 6 (noPoints), or undefined (external)
      pointPenalty = null;
      freeAttempts = null;
  }
  return { freeAttempts, pointPenalty };
};

export const checkIfStudentsHaveStartedAssessment = async (assessId: string) => {
  const studentAssessments = await apiNext.getStudentAssessmentsByAssessments([assessId], 1);
  const studentsHaveStarted = studentAssessments.length > 0;
  return studentsHaveStarted;
};

//get the hours and minutes from a datetime string
const getTimeParts = (timeString: string) => {
  const [hour, minute] = timeString.split(':');
  if (!hour || !minute) {
    return { hour: 0, minute: 0 };
  }
  return { hour: parseInt(hour), minute: parseInt(minute) };
};

export const getClassSessionIdsFromAssessments = (assessments: Array<AssessmentApiBase>, coveredAssessmentIds: Array<string>) => {
  // because summatives no longer have a concept of classSessionIds but we still need them in some places
  // this function derives unique class session ids from assessments
  return assessments.reduce((acc, cur) => {
    const { classSessionIds: csIds = [], id } = cur;
    if (coveredAssessmentIds.includes(id)) {
      return [...new Set([...acc, ...csIds])].flat();
    }
    return acc;
  }, [] as Array<number>);
};

/**
 * getDefaultDueDate implements logic for making the assessment due date easy for a faculty user to select the date they want with as little work as possible
 * Specifically:
 * - Homework or summative (study path): Updates to next class period after class Class Session Covered (if selecting 1, 3, and 5, due date defaults to Class 6 date)
 * - Preclass: updates to date of latest class period selected
 * - 6/05/2022: added support for assessment presets
 * NOTE: if anything here changes, assessment presets will likely need to be updated too
 */
export const getDefaultDates = (
  assessType: AssessTypeEnum,
  timeZone: string,
  initialDate: string,
  courseAssessmentPreset: CourseAssessmentPresetApi
) => {
  if (!initialDate) {
    throw new Error('getDefaultDates requires initialDate');
  }

  // Convert the unzonedDate to the correct date/time based on the course time zone
  // The result of `fromISO` should be the start of the day in the zone, but `startOf` is used to be explicit
  // and is essential in applying preset change to existing assessments, where the existing assessment dueDate is used, which may have time attached
  const convertDateToLocalZone = (unzonedDate: string, zone: string) => {
    return DateTime.fromISO(unzonedDate, { zone }).startOf('day').toLocal();
  };

  const initialDateInLocalZone = convertDateToLocalZone(initialDate, timeZone);
  let dueHour: number;
  let dueMinute: number;
  let dueLuxon: DateTime;
  let openHour: number;
  let openMinute: number;
  switch (assessType) {
    case AssessTypeEnum.Prep:
      ({ hour: dueHour, minute: dueMinute } = getTimeParts(courseAssessmentPreset.studyPathDueTime));
      dueLuxon = initialDateInLocalZone.plus({ hours: dueHour, minutes: dueMinute });
      return {
        open: DateTime.fromISO(initialDate).toJSDate(),
        due: dueLuxon.toJSDate(),
        late: dueLuxon.plus({ days: courseAssessmentPreset.studyPathPrepLateDateOffset }).toJSDate(),
      };
    case AssessTypeEnum.PracticeTest:
      ({ hour: dueHour, minute: dueMinute } = getTimeParts(courseAssessmentPreset.studyPathDueTime));
      dueLuxon = initialDateInLocalZone.plus({ hours: dueHour, minutes: dueMinute });
      return {
        open: DateTime.fromISO(initialDate).toJSDate(),
        due: dueLuxon.toJSDate(),
        late: dueLuxon.plus({ days: courseAssessmentPreset.studyPathPracticeLateDateOffset }).toJSDate(),
      };
    case AssessTypeEnum.Summative:
      ({ hour: dueHour, minute: dueMinute } = getTimeParts(courseAssessmentPreset.studyPathDueTime));
      dueLuxon = initialDateInLocalZone.plus({ hours: dueHour, minutes: dueMinute });
      return {
        open: DateTime.fromISO(initialDate).toJSDate(),
        due: dueLuxon.toJSDate(),
        late: null,
        prepLate: dueLuxon.plus({ days: courseAssessmentPreset.studyPathPrepLateDateOffset }).toJSDate(),
        practiceLate: dueLuxon.plus({ days: courseAssessmentPreset.studyPathPracticeLateDateOffset }).toJSDate(),
      };
    case AssessTypeEnum.Preclass:
    case AssessTypeEnum.Readiness:
      ({ hour: openHour, minute: openMinute } = getTimeParts(courseAssessmentPreset.preclassOpenTime));
      ({ hour: dueHour, minute: dueMinute } = getTimeParts(courseAssessmentPreset.preclassDueTime));
      dueLuxon = initialDateInLocalZone.plus({ hours: dueHour, minutes: dueMinute });
      return {
        open: initialDateInLocalZone
          .minus({ days: courseAssessmentPreset.preclassOpenDateOffset })
          .plus({ hours: openHour, minutes: openMinute })
          .toJSDate(),
        due: dueLuxon.toJSDate(),
        late: dueLuxon.plus({ days: courseAssessmentPreset.preclassLateDateOffset }).toJSDate(),
      };
    case AssessTypeEnum.Homework:
    default:
      ({ hour: openHour, minute: openMinute } = getTimeParts(courseAssessmentPreset.homeworkOpenTime));
      ({ hour: dueHour, minute: dueMinute } = getTimeParts(courseAssessmentPreset.homeworkDueTime));
      dueLuxon = initialDateInLocalZone.plus({ hours: dueHour, minutes: dueMinute });
      return {
        open: initialDateInLocalZone
          .minus({ days: courseAssessmentPreset.homeworkOpenDateOffset })
          .plus({ hour: openHour, minute: openMinute })
          .toJSDate(),
        due: dueLuxon.toJSDate(),
        late: dueLuxon.plus({ days: courseAssessmentPreset.homeworkLateDateOffset }).toJSDate(),
      };
  }
};

export const getNextValidLateDate = (assessType: AssessTypeEnum, timeZone: string, dueDate: Date, courseAssessmentPreset: CourseAssessmentPresetApi): Date => {
  const now = DateTime.now();
  const dueDateL = DateTime.fromJSDate(dueDate);
  const { late: lateDateFromPresets } = getDefaultDates(assessType, timeZone, dueDateL.toISO(), courseAssessmentPreset) as { late: Date };
  if (DateTime.fromJSDate(lateDateFromPresets) < now) {
    // if lateDate based on presets would be in the past, use tomorrow instead
    const { hour, minute, second, millisecond } = dueDateL;
    return now.plus({ days: 1 }).set({ hour, minute, second, millisecond }).toJSDate();
  }
  return lateDateFromPresets;
};


export interface AvailableAssessmentForAssessmentBuilder {
  assessmentId: string
  assessType: AssessTypeEnum
  classDate: string
  classDates: Array<string>
  courseLearningObjectives: Array<EnrichedCourseLearningObjective>
  dueDate: string
  name: string
  nextSummativeId?: string
}

export const getStudyPathEligibleAssessments = (
  studyPathAssessment: SummativeAssessmentApi,
  assessments: Array<{ dueDate: string; assessType: AssessTypeEnum; id: string; name: string }>,
  aqms: Array<MappedAssessmentQuestion>,
  coveredAssessmentIds: Array<string>, // pass in covered assessments, in case assessment got moved to after the SP
  courseTimeZone: string
) => {
  const { dueDate: studyPathDueDate, id: summativeId, prep, practiceTest } = studyPathAssessment;
  // get end of day on study path due date to include any assessments that fall on the same day in course's timezone
  const targetDate = DateTime.fromISO(studyPathDueDate, { zone: courseTimeZone }).endOf('day');

  // get list of current summatives before targetDate for grouping available assessments, this is how we get the default selection for Assessments Covered
  const currentSummatives = assessments.filter(({ assessType, dueDate }) => assessType === AssessTypeEnum.Summative && +DateTime.fromISO(dueDate, { zone: courseTimeZone }) < +targetDate);
  // filter to get only selectable assessments before targetDate
  const assessmentsBeforeDueDateOrCovered = assessments.filter(({ dueDate, id }) => DateTime.fromISO(dueDate, { zone: courseTimeZone }) < targetDate || coveredAssessmentIds.includes(id));
  const availableAssessments = assessmentsBeforeDueDateOrCovered.reduce((acc, { assessType, dueDate, id, name }) => {
    // summatives should never appear in eligible assessments
    // target study path prep and practice assessments should never be in eligible assessments
    if (assessType === AssessTypeEnum.Summative || [summativeId, prep?.id, practiceTest?.id].includes(id)) {
      return acc;
    }
    const assessmentAqms = aqms.filter(({ assessmentId }) => assessmentId === id);
    // get next summative id to enable grouping of available assessments
    // if nextSummativeId is undefined, we can infer that the assessment is between the last practice test and the one we're currently creating
    const { id: nextSummativeId } = currentSummatives.find(({ dueDate: summativeDueDate }) =>
      // if is same dueDate as summative (prep/practice) or next chronological summative (hw/pre)
      +DateTime.fromISO(summativeDueDate, { zone: courseTimeZone }) >= +DateTime.fromISO(dueDate, { zone: courseTimeZone })
    ) || {};
    const allClos = assessmentAqms.map(({ _derived: { courseLearningObjectives } }) => courseLearningObjectives);
    const uniquedClos = [...new Set([...allClos].flat())];

    const availableAssessment = {
      assessmentId: id,
      assessType,
      courseLearningObjectives: uniquedClos,
      dueDate,
      name,
      nextSummativeId,
    } as AvailableAssessmentForAssessmentBuilder;

    if (assessmentAqms) {
      return [
        ...acc,
        availableAssessment,
      ];
    }
    return acc;
  }, [] as Array<AvailableAssessmentForAssessmentBuilder>);
  return availableAssessments;
};

export const getDefaultMultipleAttemptPolicy = (assessTypeQuery: AssessTypeEnum, preset: CourseAssessmentPresetApi) => {
  switch (assessTypeQuery) {
    case AssessTypeEnum.Readiness:
    case AssessTypeEnum.Preclass:
      return determineMultipleAttemptPolicy(
        preset.preclassFreeAttempts,
        preset.preclassPointPenalty,
        preset.preclassGradingPolicy
      );
    case AssessTypeEnum.Homework:
      return determineMultipleAttemptPolicy(
        preset.homeworkFreeAttempts,
        preset.homeworkPointPenalty,
        preset.homeworkGradingPolicy
      );
    case AssessTypeEnum.Prep:
      return determineMultipleAttemptPolicy(
        preset.studyPathPrepFreeAttempts,
        preset.studyPathPrepPointPenalty,
        preset.studyPathPrepGradingPolicy
      );
    case AssessTypeEnum.PracticeTest:
      return determineMultipleAttemptPolicy(
        preset.studyPathPracticeFreeAttempts,
        preset.studyPathPracticePointPenalty,
        preset.studyPathPracticeGradingPolicy
      );
    case AssessTypeEnum.Summative:
    default:
      return MultipleAttemptPolicyEnum.NotFound;
  }
};

export const getPresetLatePolicy = (assessType: AssessTypeEnum, preset: CourseAssessmentPresetApi) => {
  switch (assessType) {
    case AssessTypeEnum.Readiness:
    case AssessTypeEnum.Preclass:
      return preset.preclassLatePolicy;
    case AssessTypeEnum.Homework:
      return preset.homeworkLatePolicy;
    case AssessTypeEnum.Prep:
      return preset.studyPathPrepLatePolicy;
    case AssessTypeEnum.PracticeTest:
      return preset.studyPathPracticeLatePolicy;
    default:
      return YesNo.No;
  }
};

export const getPresetLatePenalty = (assessType: AssessTypeEnum, preset: CourseAssessmentPresetApi) => {
  switch (assessType) {
    case AssessTypeEnum.Readiness:
    case AssessTypeEnum.Preclass:
      return preset.preclassLatePenalty;
    case AssessTypeEnum.Homework:
      return preset.homeworkLatePenalty;
    case AssessTypeEnum.Prep:
      return preset.studyPathPrepLatePenalty;
    case AssessTypeEnum.PracticeTest:
      return preset.studyPathPracticeLatePenalty;
    default:
      return null;
  }
};

export const inferGradingPolicy = (policy: MultipleAttemptPolicyEnum) => {
  switch (policy) {
    case MultipleAttemptPolicyEnum.ForCompletion:
      return GradingPolicyEnum.Completion;
    case MultipleAttemptPolicyEnum.NotForPoints:
      return GradingPolicyEnum.NoPoints;
    default:
      return GradingPolicyEnum.Correctness;
  }
};

export const getTargetTabFromAssessType = (assessType: AssessTypeEnum) => {
  switch (assessType) {
    case AssessTypeEnum.Homework:
    case AssessTypeEnum.Preclass:
      return TabEnum.SelectQuestions;
    case AssessTypeEnum.Prep:
      return TabEnum.SelectPrepQuestions;
    case AssessTypeEnum.PracticeTest:
      return TabEnum.SelectPracticeQuestions;
    default:
      return TabEnum.Editing;
  }
};

/****
/* this does the reverse translation of calculating multipleAttemptPolicy from freeAttempts and pointPenalty and other needed settings
*/
export function determineMultipleAttemptPolicy(
  freeAttempts: number | null,
  pointPenalty: number | null,
  gradingPolicy: GradingPolicyEnum
) {
  switch (gradingPolicy) {
    case GradingPolicyEnum.Correctness:
      if (freeAttempts === null && pointPenalty === 0) {
        return MultipleAttemptPolicyEnum.CorrectnessTypeOne;
      } else if (freeAttempts === 2 && pointPenalty === 0.1) {
        return MultipleAttemptPolicyEnum.CorrectnessTypeTwo;
      } else if (freeAttempts === 1 && pointPenalty === 0.1) {
        return MultipleAttemptPolicyEnum.CorrectnessTypeThree;
      } else if (freeAttempts === 1 && pointPenalty === 1) {
        return MultipleAttemptPolicyEnum.CorrectnessTypeFour;
      }
      console.error('Correctness attempt Policy not found for freeAttempts, pointPenalty, gradingPolicy', freeAttempts, pointPenalty, gradingPolicy);
      return MultipleAttemptPolicyEnum.NotFound;
    case GradingPolicyEnum.Completion: {
      //there is only one CompletiontMultipleAttemptPolicy at the moment. Jeff bets $20 there will never be more than one of these
      // 5 months later, double or nothing this is an improvement and we will never need to revert this cleanup
      return MultipleAttemptPolicyEnum.ForCompletion;
    }
    case GradingPolicyEnum.NoPoints: {
      // there is only one NoPoints at the moment. Hope bets $20 there will never be more than one of these
      return MultipleAttemptPolicyEnum.NotForPoints;
    }
    default: {
      return MultipleAttemptPolicyEnum.NotFound;
    }
  }
}

// convenience function for determining MAP from the full Assessment object
export function determineMultipleAttemptPolicyFromAssessment({
  freeAttempts,
  pointPenalty,
  gradingPolicy,
}: AssessmentApiBase) {
  return determineMultipleAttemptPolicy(freeAttempts, pointPenalty, gradingPolicy);
}

export function cleanupLatePolicyForGradingPolicy<T extends Date | string>({ gradingPolicy, lateDate, latePenalty, latePolicy }: { gradingPolicy: GradingPolicyEnum; lateDate: T | null; latePenalty: number | null; latePolicy: YesNo }) {
  if (gradingPolicy === GradingPolicyEnum.NoPoints) {
    return {
      latePolicy: YesNo.No,
      lateDate: null,
      latePenalty: null,
    };
  }
  return {
    latePolicy,
    lateDate,
    latePenalty,
  };
}

export const getAllAssessmentCourseLos = (assessmentId: string, assessmentQuestionMaps: Array<MappedAssessmentQuestion>) => {
  const assessmentAqms = assessmentQuestionMaps.filter((aqm) => aqm.assessmentId === assessmentId);
  const allClos = assessmentAqms.map(({ _derived: { courseLearningObjectives } }) => courseLearningObjectives);
  return getUniqueLosSortedByLoNumber(allClos.flat());
};

export const calculateAssessmentTotalPoints = (assessmentId: string, assessmentQuestionMaps: Array<MappedAssessmentQuestion>) => {
  return assessmentQuestionMaps.reduce((r, aqm) => {
    if (aqm.assessmentId === assessmentId) {
      return r + aqm.points;
    }
    return r;
  }, 0);
};

export const determineDefaultMinOpenMaxDueDatesForCourse = (course: CourseApi): { defaultMinOpenDate: Date; defaultMaxDueDate: Date } => {
  const defaultMinOpenDate = DateTime.fromISO(course.accessDate).minus({ days: 7 }).toJSDate();
  const defaultMaxDueDate = DateTime.fromISO(course.endDate).plus({ days: 7 }).toJSDate();
  return {
    defaultMinOpenDate,
    defaultMaxDueDate,
  };
};

export const getMinDueDateForAssessment = (isStarted: boolean, isSummative: boolean, defaultMinOpenDate: Date): Date => {
  if (isStarted || isSummative) {
    return DateTime.local().toJSDate(); // if started or summative assessment, due can only be in the future
  }
  return defaultMinOpenDate;
};

export const getIsCloseToPracticeTestDue = (dueDate: string): boolean => {
  return DateTime.fromISO(dueDate).minus({ days: 3 }) <= DateTime.local();
};

type SimpleComparePrepPractice = Pick<AssessmentApiBase, 'gradingPolicy' | 'pointPenalty' | 'freeAttempts' | 'isGradeSyncEnabled' | 'latePolicy' | 'lateDate' | 'latePenalty' | 'published'>
export const comparePrepPracticeAssessments = (current: SimpleComparePrepPractice, modified: SimpleComparePrepPractice) => {
  // would be great to have a unified `policy` value so we don't have to check all this
  const comparisons = [
    current.gradingPolicy !== modified.gradingPolicy,
    current.pointPenalty !== modified.pointPenalty,
    current.freeAttempts !== modified.freeAttempts,
    current.isGradeSyncEnabled !== modified.isGradeSyncEnabled,
    current.latePolicy !== modified.latePolicy,
    !!current.lateDate !== !!modified.lateDate, // check presence of lateDate
    current.lateDate !== modified.lateDate,     // check value of lateDate
    current.latePenalty !== modified.latePenalty,
    current.published !== modified.published,
  ];
  return comparisons.some((val) => !!val);
};

/* Sort Assessments by AssessTypeEnum */
export const assessTypeSortWeight = {
  [AssessTypeEnum.Readiness]: 1,
  [AssessTypeEnum.Preclass]: 2,
  [AssessTypeEnum.Homework]: 3,
  [AssessTypeEnum.Prep]: 4,
  [AssessTypeEnum.PracticeTest]: 5,
  [AssessTypeEnum.Summative]: 6,
};

export const sortAssessmentsByWeightedAssessType = <T extends Pick<AssessmentApiBase, 'assessType'>>(assessmentsArray: Array<T>): Array<T> =>
  assessmentsArray.sort((a, b) => assessTypeSortWeight[a.assessType] - assessTypeSortWeight[b.assessType]);

export const determineAssessmentWindow = (dueDate: string, lateDate: string | null, eaDueDate: string | null): FirstAttemptedEnum => {
  // reference: note different inputs and types
  // https://github.com/codonlearning/backend/blob/dev/src/hooks/determineAssessmentWindow.hook.ts
  const nowL = DateTime.now();
  const dueDateL = !!eaDueDate ? DateTime.fromISO(eaDueDate) : DateTime.fromISO(dueDate);
  const lateDateL = !!lateDate ? DateTime.fromISO(lateDate) : null;
  if (nowL < dueDateL) {
    return FirstAttemptedEnum.BeforeDue;
  } else if (!!lateDateL && nowL < lateDateL) {
    return FirstAttemptedEnum.BeforeLate;
  }
  return FirstAttemptedEnum.AfterLate;
};
// this is paired with StudentAssessmentQuestionAttemptApiCreateOut
export interface ValidatedInstructorAttempt extends Omit<StudentAssessmentQuestionAttemptApiCreateOut, 'id' | 'createdAt' | 'updatedAt' | 'assessmentQuestionId'> {
  latePointsDeducted: number | null
  assessmentQuestionId?: number
}

export const validateInstructorVatAttempt = (
  assessmentData: AssessmentWithEnrollment,
  data: L8yContainerValidated,
  studentAssessmentId: number
): ValidatedInstructorAttempt => {
  // references:
  // https://github.com/codonlearning/backend/blob/dev/src/services/studentAssessmentQuestionAttempts/hooks/calculateAdjustedPointsEarned.hook.ts
  // https://github.com/codonlearning/backend/blob/dev/src/services/studentAssessmentQuestionAttempts/hooks/createAttemptUpdateStudentAssessmentQuestion.hook.ts
  const {
    assessType,
    attemptsInAt,
    dueDate,
    freeAttempts,
    gradingPolicy,
    lateDate,
    latePenalty,
    pointPenalty,
  } = assessmentData;
  const {
    score,
    isCorrect,
    assessmentQuestionId,
    clarity,
    attemptData,
    rawMaxScore,
    assessmentQuestionPoints = 0,
    previousAttemptNum,
    previousPointsEarned,
    previousLatePointsDeducted,
  } = data;
  const newStudentAttempt = {
    studentAssessmentId,
    assessmentQuestionId,
    location: AssessmentLocation.AT,
    isCorrect,
    rawPointsEarned: score,
    attemptData,
    clarity,
    rawMaxScore,
  };
  const assessmentWindow = determineAssessmentWindow(dueDate, lateDate, null);
  const attemptNum = previousAttemptNum + 1;
  let pointsEarned = 0;
  let vatFrozen = YesNo.No;
  let latePointsDeducted = null;
  switch (gradingPolicy) {
    case GradingPolicyEnum.Completion:
      ({ adjustedPointsEarned: pointsEarned, latePointsDeducted } = calculatePointsEarnedCompletion(assessmentQuestionPoints, assessmentWindow, { latePenalty }));
      break;
    case GradingPolicyEnum.Correctness:
      ({ adjustedPointsEarned: pointsEarned, latePointsDeducted } = calculatePointsEarnedCorrectness(assessmentQuestionPoints, assessmentWindow, { freeAttempts, pointPenalty, latePenalty }, { rawPointsEarned: score, attemptNum, rawMaxScore }));
      break;
    case GradingPolicyEnum.NoPoints:
    case GradingPolicyEnum.External:
    default:
      pointsEarned = 0;
      latePointsDeducted = assessmentWindow !== FirstAttemptedEnum.AfterLate ? 0 : null;
  }
  if (previousPointsEarned !== null) {
    // don't let points go down
    if (previousPointsEarned > pointsEarned) {
      pointsEarned = previousPointsEarned;
      latePointsDeducted = previousLatePointsDeducted;
    }
  }

  // only freeze VAT for instructors if out of attempts, or after late and not REX, or is correct and not REX
  if ((attemptsInAt !== null && attemptNum >= attemptsInAt) || (assessType !== AssessTypeEnum.Readiness && (assessmentWindow === FirstAttemptedEnum.AfterLate || isCorrect === YesNo.Yes))) {
    vatFrozen = YesNo.Yes;
  }
  return {
    ...newStudentAttempt,
    attemptNum,
    adjustedPointsEarned: pointsEarned,
    gradedAdjustedPointsEarned: pointsEarned,
    freePlay: YesNo.Yes, // don't show recap
    latePointsDeducted,
    vatFrozen,
  };
};

export const totalFromPointsHash = (pointsHash: PointsHash): number => {
  return Object.values(pointsHash).reduce((acc: number, cur) => {
    if (cur === null) {
      return acc;
    }
    acc = acc + cur;
    return acc;
  }, 0);
};

export const handleFinishInstructor = (pointsHash: PointsHash, correctHash: CorrectHash): { totalPointsEarned: number; correctQuestionCount: number } => {
  const totalPointsEarned = totalFromPointsHash(pointsHash);
  const correctQuestionCount = Object.values(correctHash).filter(v => v === YesNo.Yes).length;
  return {
    totalPointsEarned,
    correctQuestionCount,
  };
};

export const formatStudyPathPlaceholderBasedOnPreset = (
  dueDateISO: string,
  summativeName: string,
  preset: CourseAssessmentPresetApi
) => {
  const {
    studyPathPracticeFreeAttempts,
    studyPathPracticeGradingPolicy,
    studyPathPracticeLatePenalty,
    studyPathPracticeLatePolicy,
    studyPathPracticePointPenalty,
    studyPathPrepFreeAttempts,
    studyPathPrepGradingPolicy,
    studyPathPrepLatePenalty,
    studyPathPrepLatePolicy,
    studyPathPrepPointPenalty,
  } = preset;
  const summativeAssessmentWithPresets: FormValues = {
    assessType: AssessTypeEnum.Summative,
    dueDate: DateTime.fromISO(dueDateISO).toJSDate(),
    gradingPolicy: GradingPolicyEnum.External,
    instructions: '',
    isGradeSyncEnabled: false,
    lateDate: null,
    latePenalty: null,
    latePolicy: YesNo.No,
    name: summativeName,
    multipleAttemptPolicy: MultipleAttemptPolicyEnum.NotFound,
    openDate: DateTime.fromISO(dueDateISO).minus({ minutes: 1 }).toJSDate(),
    published: YesNo.No,
    prep: {
      freeAttempts: studyPathPrepFreeAttempts,
      gradingPolicy: studyPathPrepGradingPolicy,
      pointPenalty: studyPathPrepPointPenalty,
      isGradeSyncEnabled: false,
      lateDate: null,
      latePenalty: studyPathPrepLatePenalty,
      latePolicy: studyPathPrepLatePolicy,
      published: YesNo.No,
    },
    practiceTest: {
      freeAttempts: studyPathPracticeFreeAttempts,
      gradingPolicy: studyPathPracticeGradingPolicy,
      pointPenalty: studyPathPracticePointPenalty,
      isGradeSyncEnabled: false,
      lateDate: null,
      latePenalty: studyPathPracticeLatePenalty,
      latePolicy: studyPathPracticeLatePolicy,
      published: YesNo.No,
    },
  };
  return summativeAssessmentWithPresets;
};

// translate from FormValues to API formatting
export const formatAssessmentForApi = (
  assessType: AssessTypeEnum,
  assessmentDetails: FormValues,
  courseId: string
): CreateAssessmentBody | CreateSummativeAssessmentBody => {
  const {
    classSessionIds,
    dueDate,
    gradingPolicy,
    instructions,
    isGradeSyncEnabled,
    lateDate,
    latePenalty,
    latePolicy,
    multipleAttemptPolicy,
    name,
    openDate,
    published,
    prep: modifiedPrep,
    practiceTest: modifiedPracticeTest,
  } = assessmentDetails;

  const { freeAttempts, pointPenalty } = determineFreeAttemptsAndPointPenalty(multipleAttemptPolicy);

  const baseAssessmentValues = {
    dueDate: DateTime.fromJSDate(dueDate).toISO(),
    freeAttempts,
    gradingPolicy,
    isGradeSyncEnabled,
    lateDate: !!lateDate ? DateTime.fromJSDate(lateDate).toISO() : null,
    latePenalty,
    latePolicy,
    name,
    openDate: DateTime.fromJSDate(openDate).toISO(),
    pointPenalty,
    published,
    assessType: assessmentDetails.assessType,
    courseId,
    instructions,
  };

  if (assessType === AssessTypeEnum.Summative) {
    if (!!modifiedPrep && !!modifiedPracticeTest) {
      const cleanedUpPrep = cleanupLatePolicyForGradingPolicy<Date>(modifiedPrep);
      const updatedPrep = {
        ...modifiedPrep,
        latePolicy: cleanedUpPrep.latePolicy,
        lateDate: !!cleanedUpPrep.lateDate ? DateTime.fromJSDate(cleanedUpPrep.lateDate).toISO() : cleanedUpPrep.lateDate,
        latePenalty: cleanedUpPrep.latePenalty,
      };
      const cleanedUpPracticeTest = cleanupLatePolicyForGradingPolicy<Date>(modifiedPracticeTest);
      const updatedPracticeTest = {
        ...modifiedPracticeTest,
        latePolicy: cleanedUpPracticeTest.latePolicy,
        lateDate: !!cleanedUpPracticeTest.lateDate ? DateTime.fromJSDate(cleanedUpPracticeTest.lateDate).toISO() : cleanedUpPracticeTest.lateDate,
        latePenalty: cleanedUpPracticeTest.latePenalty,
      };
      return {
        ...baseAssessmentValues,
        gradingPolicy: GradingPolicyEnum.External, // force grading policy external for summative
        openDate: DateTime.fromJSDate(assessmentDetails.dueDate).minus({ minutes: 1 }).toISO(), // set correct open date even if datepicker hasn't changed
        latePolicy: YesNo.No,
        lateDate: null,
        latePenalty: null,
        prep: updatedPrep,
        practiceTest: updatedPracticeTest,
      } as CreateSummativeAssessmentBody;
    }
  }
  const cleanedUpNonSummative = cleanupLatePolicyForGradingPolicy<string>(baseAssessmentValues);
  return {
    ...baseAssessmentValues,
    classSessionIds,
    latePolicy: cleanedUpNonSummative.latePolicy,
    lateDate: cleanedUpNonSummative.lateDate,
    latePenalty: cleanedUpNonSummative.latePenalty,
  } as CreateAssessmentBody;
};

export const getAssessmentStatus = (startedByStudents: boolean, published: YesNo) => {
  if (startedByStudents) {
    return AssessmentStatus.Started;
  }
  return published === YesNo.Yes
    ? AssessmentStatus.Published
    : AssessmentStatus.Unpublished;
};

export interface AssessmentWithAddRemoveStartedInfo extends AssessmentApiBase {
  canBumpTo: boolean
  canRemoveFrom: boolean
  started: boolean
}

// Bump logic:
// 1. You can never bump to summatives
// 2. You can bump to any other assessment that has never been started by students, even if it is past due
// 3. You can also bump to a prep assessment that has been started but is not past late date (if it has a late policy) or not past due (if it doesn't have a late policy)
// Remove logic:
// 1. You can never remove from summatives
// 2. You can remove from any other assessment that has never been started by students, even if it is past due
// 3. You can also remove from an in progress assessment (one that has been started but is not past late date (if it has a late policy) or not past due (if it doesn't have a late policy))
export async function getAssessmentsWithAddRemoveStartedInfo(assessments: Array<AssessmentApiBase>) {
  const assessmentsThatNeedStudentDataCheck = assessments.filter(a => a.assessType !== AssessTypeEnum.Summative);
  const studentAssessments = await apiNext.getStudentAssessmentsByAssessments(assessmentsThatNeedStudentDataCheck.map(a => a.id));
  const bumpRemoveAvailableAssessmentsData = assessments.reduce((acc: Array<AssessmentWithAddRemoveStartedInfo>, cur) => {
    if (cur.assessType !== AssessTypeEnum.Summative && !studentAssessments.find(sa => sa.assessmentId === cur.id)) {
      // if is not summative and has not been started by students, can bump to it and remove from it
      acc.push({ ...cur, canBumpTo: true, canRemoveFrom: true, started: false });
    } else if (studentAssessments.find(sa => sa.assessmentId === cur.id)) {
      const started = true;
      if ((!!cur.lateDate && +DateTime.fromISO(cur.lateDate) < +DateTime.now()) || (!cur.lateDate && +DateTime.fromISO(cur.dueDate) < +DateTime.now())) {
        // if key dates are in the past and it has been started, can remove from it but not bump to it
        acc.push({ ...cur, canBumpTo: false, canRemoveFrom: true, started });
      } else {
        // if key dates are in the future and it has been started, can remove from it, but only bump to it if it is a prep assessment
        const canBumpTo = cur.assessType === AssessTypeEnum.Prep;
        acc.push({ ...cur, canBumpTo, canRemoveFrom: true, started });
      }
    }
    return acc;
  }, []);
  return bumpRemoveAvailableAssessmentsData;
}

export const handleStudentAttemptResponse = async ({
  assessmentQuestionId,
  location,
  studentAssessmentId,
  studentAttemptResponse,
  triggerConfirmationPrompt,
}: {
  assessmentQuestionId: number
  location: AssessmentLocation
  studentAssessmentId: number
  studentAttemptResponse: ErrorableResponse<StudentAssessmentQuestionAttemptApi>
  triggerConfirmationPrompt: (props: ConfirmationPromptProps) => void
}) => {
  console.debug(`handleStudentAttemptResponse ${assessmentQuestionId} in location: ${location}`, studentAttemptResponse);
  const { error } = studentAttemptResponse as SimplifiedErrorResponse;
  if (!!error) {
    console.warn(`Error creating student attempt in the ${location}`, error);
    if (error.errorCode === CodonErrorCode.CreateSAQAAQNotFound) {
      saveLogMessage(`A student encountered an error in the ${location} where a question was removed after the ${location} was loaded, AQId: ${assessmentQuestionId}, SAId: ${studentAssessmentId}`);
      triggerConfirmationPrompt({
        title: assessmentTakerWarningStrings.IN_PROGRESS_QUESTION_REMOVAL_WARNING_TITLE,
        message: assessmentTakerWarningStrings.IN_PROGRESS_QUESTION_REMOVAL_WARNING_MESSAGE,
        confirmationType: ConfirmationTypeEnum.Warn,
        onConfirm: () => {return;},
      });
      return;
    } else {
      throw new Error(`Unexpected error in studentAttemptResponse [${location}] - ${JSON.stringify(error)}`);
    }
  }
  return studentAttemptResponse as StudentAssessmentQuestionAttemptApi;
};
